.toggle-light .toggle-slide {
  border-radius: 9999px;
  box-shadow: 0 0 0 1px #999;
}
.toggle-light .toggle-on,
.toggle-light .toggle-off {
  font-size: 11px;
  font-weight: 500;
}
.toggle-light .toggle-on,
.toggle-light .toggle-select .toggle-inner .active {
  background: #45a31f;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
}
.toggle-light .toggle-off,
.toggle-light .toggle-select .toggle-on {
  color: rgba(0, 0, 0, 0.6);
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
  background: -webkit-linear-gradient(#cfcfcf, #f5f5f5);
  background: linear-gradient(#cfcfcf, #f5f5f5);
}
.toggle-light .toggle-blob {
  border-radius: 50px;
  background: -webkit-linear-gradient(#f5f5f5, #cfcfcf);
  background: linear-gradient(#f5f5f5, #cfcfcf);
  box-shadow: 1px 1px 2px #888;
}
.toggle-light .toggle-blob:hover {
  background: -webkit-linear-gradient(#e4e4e4, #f9f9f9);
  background: linear-gradient(#e4e4e4, #f9f9f9);
}
