.toggle-slide {
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  direction: ltr;
  text-align: center;
}
div.disabled > .toggle-slide {
  opacity: 0.7;
  pointer-events: none;
}
.toggle-slide .toggle-on,
.toggle-slide .toggle-off,
.toggle-slide .toggle-blob {
  float: left;
}
.toggle-slide .toggle-blob {
  position: relative;
  z-index: 99;
  cursor: hand;
  cursor: grab;
}
